import Link from "next/link";
import propTypes from "prop-types"

interface Props {
    name: string;
    image: string;
    age: string;
    gender: string;
    location?: string;
    username?: string;
}

const UserCard = ({ name, image, age, gender, username }: Props) => {
    return (
        <Link href="/member/[id]" as={`/member/${username}`}>
            <div className="relative w-48 h-48 rounded-xl overflow-hidden bg-gray-500">
                {gender && (
                    <div className="absolute top-2 right-2 z-20  rounded-full">
                        <img
                            src={`/assets/images/${gender.toLowerCase()}.svg`}
                            alt=""
                            className=""
                        />
                    </div>
                )}
                <div className="absolute bottom-4 left-4 font-bold text-white z-20">
                    {name}
                    {age ? `, ${age}` : ''}
                </div>
                <div className="absolute bottom-0 left-0 bg-card-gradient h-full w-full"></div>
                <img src={image} alt="" className="" />
            </div>
        </Link>
    )
}

UserCard.propTypes = {
    name: propTypes.string.isRequired,
    image: propTypes.string.isRequired,
    age: propTypes.string.isRequired,
    gender: propTypes.string.isRequired,
    location: propTypes.string,
}

export default UserCard