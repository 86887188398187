import { config } from '@lib/config'

export default function Filters({ onFilterChange, onFilterReset }) {
    if (config.featureFlags.newFilters) {
        return (
            <div className="flex flex-col mb-8">
                <div className="">
                    <form className="">
                        <div className="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
                            <div className="flex flex-col">
                                {/* <label
                                    htmlFor="gender"
                                    className="text-sm font-medium text-stone-600"
                                >
                                    Gender
                                </label> */}
                                <select
                                    onChange={onFilterChange}
                                    name="gender"
                                    className="block w-full rounded-md border border-gray-100 bg-gray-100 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                >
                                    <option value="">Filter gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                            <div className="flex flex-col">
                                {/* <label
                                    htmlFor="order"
                                    className="text-sm font-medium text-stone-600"
                                >
                                    order
                                </label> */}
                                <select
                                    onChange={onFilterChange}
                                    name="order"
                                    className="block w-full rounded-md border border-gray-100 bg-gray-100 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                >
                                    <option value="default" disabled selected>
                                        Filter order
                                    </option>
                                    <option value="registered">
                                        Newest users
                                    </option>
                                    <option value="rand">Most popular</option>
                                </select>
                            </div>
                            <div className="hidden md:flex flex-col"></div>
                            <div className="flex flex-col">
                                <button
                                    onClick={() => onFilterReset()}
                                    type="button"
                                    className="active:scale-95 rounded-lg bg-gray-200 px-8 py-2 font-medium text-gray-600 outline-none focus:ring hover:opacity-90"
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex justify-between">
                <div className="w-2/3"></div>
                <div className="w-1/3 flex gap-4 items-center justify-between mb-4">
                    <p className="font-medium">Filter</p>
                    <select
                        onChange={onFilterChange}
                        name="gender"
                        className="min-w-full px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:ring-0 text-sm"
                    >
                        <option value="">Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
            </div>
        )
    }
}
