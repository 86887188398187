export const config = {
    per_page: 12, // number of member results per page,
    creditsPerMessage: 10, // Cost (in credits) to send a message
    creditOptions: [
        {
            value: 100,
            cost: 10,
        },
        {
            value: 500,
            cost: 50,
        },
        {
            value: 10000,
            cost: 100,
        },
    ],
    earnings: [
        {
            userType: 'owners',
            tier: [
                {
                    name: 'bronze',
                    subscriptions: 5, // To be used as a percentage
                    message: 0.05, // 5p per message
                },
                {
                    name: 'silver',
                    subscriptions: 10,
                    message: 0.1,
                },
                {
                    name: 'gold',
                    subscriptions: 15,
                    message: 0.15,
                },
                {
                    name: 'platinum',
                    subscriptions: 20,
                    message: 0.2,
                },
            ],
        },
        {
            userType: 'affiliates',
            tier: [
                {
                    name: '0',
                    value: 0,
                    subscriptions: 2, // To be used as a percentage
                    message: 0.02, // 2p per message
                },
                {
                    name: '25,000',
                    value: 25000,
                    subscriptions: 3,
                    message: 0.03,
                },
                {
                    name: '100,000',
                    value: 100000,
                    subscriptions: 5,
                    message: 0.05,
                },
            ],
        },
    ],
    supportEmail: 'support@datingkit.io',
    featureFlags: {
        saleBanner: false,
        hideImages: process.env.isDev === 'true' ? true : false,
        newFilters: false
    },
}

// module.exports = config
